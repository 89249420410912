export const useUserStore = defineStore('user', () => {
  const { $api } = useNuxtApp()
  const token = useCookie<string | null>('access')
  const refresh = useCookie<string | null>('refresh')
  const user = ref<User>({
    id: 0,
    name: '',
    email: '',
    is_operator: false,
    is_company_admin: false,
  })
  const errors = ref<any>()
  const tokenErrors = ref<any>()
  const accessToken = ref<string | null>(null)
  const refToken = ref<string | null>(null)
  const cryptoString = ref<string | null>(null)

  async function getUserProfile(authToken: string | null = null) {
    const userAuth = authToken || token.value
    try {
      const res: User | null = await $api<User>(apiProfile, {
        method: 'POST',
        headers: { Authorization: `Bearer ${userAuth}` },
        async onResponse({ response }) {
          if (response.status === 403) {
            const resp = await refreshToken() as string
            if (resp) {
              await useAsyncData('profile', () => getUserProfile(resp))
            }        
          }
        },
      })
      
      if (res) {
        user.value = {
          id: res?.id,
          name: res?.name,
          email: res?.email,
          is_operator: res?.is_operator,
          is_company_admin: res?.is_company_admin,
          company: res?.company 
            ? {token: res?.company.token} 
            : {}
        }
      }
      getCryptoStringForLink()
    } catch (error) {
      errors.value = error
    }
  }

  async function getCryptoStringForLink() {
    const obj = user.value?.company?.token 
    ? { user_id: user.value.id, company_token: user.value.company.token } 
    : { user_id: user.value.id}
    const data = JSON.stringify(obj)
    const { result } = await $fetch('/api/crypto', {
      method: 'POST',
      body: {data}
    })
    cryptoString.value = result
  }

  async function refreshToken() {
    if (!refresh.value) {
      token.value = null
      await navigateTo(`/auth/login`)
    } else {
      const body = {
        "refresh": refresh.value
      }
      const { data, error } = await useApi<Token>(apiRefreshToken, {
        method: 'POST',
        body,
      })
  
      const res: Token | null = data.value
      if (res) {
          token.value = res.access
          refresh.value = res.refresh
          refreshCookie('access')
          refreshCookie('refresh')
          accessToken.value = res.access
          refToken.value = res.refresh
          return res.access
      }
      if (error.value) {
        tokenErrors.value = error.value.data
        await navigateTo(`/auth/login`)
      }  
    }
  }

  async function getToken(values: {}) {
    tokenErrors.value = null
    try {
      await $api<Token>(apiToken, {
        method: 'POST',
        body: values,
        onResponse({ response }) {
          token.value = response._data.access
          refresh.value = response._data.refresh
        }
      })
    } catch (error: any) {
      tokenErrors.value = error.data
    }
  }

  async function logout() {
    errors.value = null
    try {
      await $api(apiLogout, {
        method: 'POST',
        body: {
          'revoke_token': false,
        },
        async onResponseError({ response }) {
          if (response.status === 403) {
            await refreshToken()
            await logout()
          }
        }
      })
    } catch (error) {
      errors.value = error
    } finally {
      token.value = null
      refresh.value = null
      user.value = {
        id: 0,
        name: '',
        email: '',
        is_operator: false,
        is_company_admin: false,
      }
    }
  }

  async function verifyRegistration(body: {}) {
    const { data, error } = await useApi(apiVerifyRegistration, {
      method: 'POST',
      body,
    })

    if (data.value) {
      //
    }
    if (error.value) {
      errors.value = error.value
    }

  }

  return {
    user,
    errors,
    tokenErrors,
    accessToken,
    refToken,
    cryptoString,
    getToken,
    getUserProfile,
    logout,
    verifyRegistration,
  }
})